import {
  faArrowsToCircle,
  faRocketLaunch,
  faWindow,
} from "@fortawesome/pro-light-svg-icons";
import { Trans } from "@lingui/macro";
import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import ArrowButton from "../../components/ArrowButton";
import Container from "../../components/Container";
import CustomizedIcon from "../../components/CustomizedIcon";
import Hero from "../../components/Hero";
import ModalTouch from "../../components/ModalTouch";
import NewLayout from "../../components/NewLayout";
import privatePartners from "../../images/fund-houses/private_partners.png";
import heroImage from "../../images/headers/alternatives_fh.png";
import imageMac from "../../images/private_market_funds.png";

const SOLUTIONS = [
  {
    icon: faArrowsToCircle,
    color: "#D50032",
    description: (
      <Trans>
        Stand alone department of 40+ professionals focused solely on
        alternative and private markets funds
      </Trans>
    ),
  },
  {
    icon: faRocketLaunch,
    color: "#1B365D",
    description: (
      <Trans>
        Tech enhancements of current platform to facilitate distribution of
        closed-ended typical drawdown funds and open-ended semi liquid funds.
      </Trans>
    ),
  },
  {
    icon: faWindow,
    color: "#5AB574",
    description: (
      <Trans>
        Handling of Pre trading, Trading, Post Trading: Client Onboarding,
        Commitments and Capital calls Management, Reports, Defaults
      </Trans>
    ),
  },
];

const DATA = [
  {
    title: <Trans>Operations</Trans>,
    subItems: [
      <Trans>
        Single Operational Set up: Trading, settlement & core services
      </Trans>,
      <Trans>Streamlined Connectivity with your Transfer Agent</Trans>,
    ],
  },
  {
    title: <Trans>Data and Insights</Trans>,
    subItems: [
      <Trans>
        Dissemination of Data, Docs and Corporate Actions according to your
        preferences
      </Trans>,
      <Trans>Increase the visibility of your products to Distributors</Trans>,
      <Trans>
        Make your educational material available on Connect, our digital
        ecosystem, (video, podcasts, reports) to reach our global distribution
        network
      </Trans>,
    ],
  },
];

function FundHouseAlternativesPage() {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <NewLayout>
        <Hero
          kind={<Trans>Alternative Asset Managers</Trans>}
          title={<Trans>Allfunds Alternative Solutions</Trans>}
          description={
            <Trans>
              Allfunds offers a fully integrated solutions for Distributors and
              for Alternative Asset Managers to access and onboard private
              Market funds.
            </Trans>
          }
          backgroundImage={heroImage}
        />
        <Container py={{ xs: 4, md: 8 }}>
          <Stack spacing={2}>
            <Typography variant="h3" mb={2}>
              <Trans>Why Allfunds Alternative Solutions</Trans>
            </Typography>
            <Box>
              <Grid container spacing={2}>
                {SOLUTIONS.map(({ icon, color, description }) => (
                  <Grid item xs={12} md={4}>
                    <Stack spacing={2}>
                      <CustomizedIcon
                        size={36}
                        padding={1.5}
                        icon={icon}
                        color={color}
                        sx={{ margin: { xs: "0 auto", md: 0 } }}
                      />
                      <Typography mt={2}>{description}</Typography>
                    </Stack>
                  </Grid>
                ))}
              </Grid>
            </Box>
            <Box
              textAlign="center"
              sx={{ marginTop: (theme) => `${theme.spacing(4)} !important` }}
            >
              <ArrowButton
                isDark
                onClick={() => setShowModal(true)}
                title={<Trans>Get in touch</Trans>}
              />
            </Box>
          </Stack>
        </Container>
        <Box py={6}>
          <Stack spacing={2} textAlign="center">
            <Typography variant="h4" color="secondary.main">
              <Trans>Alternative Asset Managers</Trans>
            </Typography>
            <Typography variant="h2">
              <Trans>Onboard your Private Markets Funds</Trans>
            </Typography>
          </Stack>
          <Grid container spacing={2} my={5}>
            <Grid item xs={12} md={5}>
              <img src={imageMac} width="100%" alt="main_image" />
            </Grid>
            <Grid item xs={12} md={5}>
              <Stack
                spacing={2}
                px={{
                  xs: 2,
                  sm: 4,
                }}
              >
                <Typography variant="h4">
                  <Trans>Contract and Onboarding</Trans>
                </Typography>
                <ul
                  style={{
                    display: "grid",
                    gap: "24px",
                  }}
                >
                  <li>
                    <Trans>
                      One single distribution agreement to reach 1000
                      distributors from 62 countries
                    </Trans>
                  </li>
                  <li>
                    <Trans>
                      Possibility to onboard any legal structure (RAIFs, Cayman
                      SPCs, ELTIFs, UCI Part II, SICAR, FCRs) and strategy
                      (Private Equity, Private Debt, Infrastructure, Real
                      Estate, Natural Resources)
                    </Trans>
                  </li>
                  <li>
                    <Trans>
                      Buy Free Model for Distributors (no transaction fees, no
                      custody fees)
                    </Trans>
                  </li>
                </ul>
              </Stack>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            backgroundColor: (theme) => theme.palette.primary.main,
            mt: { xs: 0, md: -20 },
          }}
          pb={6}
        >
          <Container>
            <Grid container spacing={4} alignItems="end">
              {DATA.map(({ title, subItems }, index) => (
                <Grid item xs={12} md={6}>
                  <Stack spacing={1} mt={index === 0 ? { xs: 0, md: 12 } : 0}>
                    <Typography color="white" variant="h4">
                      {title}
                    </Typography>
                    <ul>
                      {subItems.map((item) => (
                        <li style={{ color: "white" }}>{item}</li>
                      ))}
                    </ul>
                  </Stack>
                </Grid>
              ))}
              <Grid item xs={12} textAlign="center">
                <ArrowButton
                  onClick={() => setShowModal(true)}
                  title={<Trans>Get in touch</Trans>}
                />
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Container py={8}>
          <Grid container spacing={6}>
            <Grid item xs={12} md={6} textAlign="center">
              <img
                src={privatePartners}
                alt="private_partners"
                style={{ maxWidth: "500px", width: "100%" }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack spacing={2}>
                <Box>
                  <Typography
                    variant="text"
                    color="secondary.main"
                    fontWeight={600}
                  >
                    APP
                  </Typography>
                  <Typography variant="h2">
                    <Trans>Allfunds Private Partners programme</Trans>
                  </Typography>
                </Box>

                <ul
                  style={{
                    display: "grid",
                    gap: "24px",
                  }}
                >
                  <li>
                    <Trans>
                      Allfunds Private Partners programme designed for a
                      selected number of alternative asset managers that have
                      demonstrated their capabilities to create adapted products
                      to the wealth management segment
                    </Trans>
                  </li>
                  <li>
                    <Trans>
                      The partnership is based on providing increased visibility
                      in front of our distribution network.
                    </Trans>
                  </li>
                  <li>
                    <Trans>
                      Our partners will be able to participate in our events,
                      roundtables and put forward their educational material.
                    </Trans>
                  </li>
                </ul>
              </Stack>
            </Grid>
          </Grid>
        </Container>
      </NewLayout>
      {showModal && <ModalTouch onClose={() => setShowModal(false)} />}
    </>
  );
}

export default FundHouseAlternativesPage;
